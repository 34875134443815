var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center flex-wrap"},[_c('strong',[_vm._v(_vm._s(_vm.detail.id))]),_c('SMessageOrderStatus',{staticClass:"ml-1",attrs:{"id":_vm.detail.status_id}}),(['Auction', 'Retail'].includes(_vm.detail.type_id))?_c('SMessageIkomOrderStatus',{staticClass:"ml-1",attrs:{"id":_vm.detail.primary_status}}):_vm._e(),_c('TSpinner',{attrs:{"loading":_vm.loading}}),(_vm.showBtn)?_c('div',{staticClass:"ml-auto d-flex align-items-center"},[_vm._l((_vm.detail.steps_back),function(status){return _c('SButtonStatus',{key:status.id,attrs:{"id":status,"disabled":_vm.updating},on:{"click":function($event){return _vm.updateOrder('previous_status', status)}}})}),(!_vm.lodash.isEmpty(_vm.detail.steps_back))?_c('CIcon',{staticClass:"mx-2",staticStyle:{"transform":"rotate(90deg)"},attrs:{"name":"cil-action-undo"}}):_vm._e(),_vm._l((_vm.detail.steps_forward),function(status){return _c('SButtonStatus',{key:status.id,attrs:{"id":status,"disabled":_vm.updating},on:{"click":function($event){status == 'Debited'
            ? (_vm.showDebitConfirm = true)
            : _vm.updateOrder('next_status', status)}}})}),_c('TModalConfirm',{staticClass:"text-dark",attrs:{"show":_vm.showDebitConfirm,"content":"Are you sure you want to debit?"},on:{"update:show":function($event){_vm.showDebitConfirm=$event},"click-confirm":_vm.nextStatusDebited}}),(_vm.detail.status_id == 'Debited')?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"ml-1 my-auto"},[_vm._v("|")]),_c('SButtonDebit',{staticClass:"ml-1",attrs:{"options":{ disabled: _vm.loading }},on:{"click":_vm.debit}})],1):_vm._e(),(_vm.$slots['actions'])?_c('span',{staticClass:"mx-1"},[_vm._v("|")]):_vm._e(),_vm._t("actions"),_c('TButtonRefresh',{staticClass:"ml-1",attrs:{"options":{ disabled: _vm.loading }},on:{"click":_vm.refresh}})],2):_vm._e()],1),_c('CCardBody',[_c('CRow',{staticClass:"mb-4"},[_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '1' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Details","data":_vm.detail,"fields":_vm.detailFields,"splitLeft":7},scopedSlots:_vm._u([{key:"customer_id",fn:function(ref){
            var value = ref.value;
return [(_vm.detail.customer)?_c('TLink',{attrs:{"to":_vm.lodash.getReferenceLink('user', value),"content":_vm.detail.customer.name}}):_vm._e()]}},{key:"shipment_method_id",fn:function(ref){
            var value = ref.value;
return [_c('SMessageShipmentMethod',{attrs:{"id":value,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('shipment_method_id', $event)}}})]}},{key:"trackings",fn:function(ref){
            var value = ref.value;
return [(!_vm.lodash.isEmpty(value))?_c('SMessageTracking',{attrs:{"value":value[0].code,"checked":value[0].received,"removable":""},on:{"click-remove":function($event){return _vm.detachTracking(value[0].id)}}}):_c('TMessage',{attrs:{"creatable":""},on:{"click-create":function($event){_vm.showAddTracking = true}}})]}},{key:"special_declaration",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('special_declaration', $event)}}})]}},{key:"special_goods_fee",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"insurance_declaration",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('insurance_declaration', $event)}}})]}},{key:"insurance_fee",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"note",fn:function(ref){
            var value = ref.value;
return [_c('TMessageTextarea',{attrs:{"value":value,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('note', $event)}}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '4' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Requests","data":_vm.detail,"fields":_vm.requestFields,"splitLeft":7},scopedSlots:_vm._u([{key:"repackage",fn:function(ref){
            var value = ref.value;
return [_c('TSwitch',{attrs:{"checked":value,"disabled":_vm.updating},on:{"change":function($event){return _vm.updateOrder('repackage', $event)}}})]}},{key:"merge_package",fn:function(ref){
            var value = ref.value;
return [_c('TSwitch',{attrs:{"checked":value,"disabled":_vm.updating},on:{"change":function($event){return _vm.updateOrder('merge_package', $event)}}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '2' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Purchase cost","data":_vm.detail,"fields":_vm.goodsMoneyFields,"splitLeft":7},scopedSlots:_vm._u([{key:"purchase_cost",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"cost_of_goods",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_paid",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_unsolved",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_debited",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}},{key:"purchase_cost_outstanding",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '3' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Service charge","data":_vm.detail,"fields":_vm.serviceChargeFields,"splitLeft":7},scopedSlots:_vm._u([{key:"service_fee",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_paid",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_discount",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('service_fee_discount', $event)}}})]}},{key:"service_fee_unsolved",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"box_service_fee",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_debited",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"service_fee_outstanding",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '5' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Other fees","data":_vm.detail,"fields":_vm.additionalCostFields,"splitLeft":7},scopedSlots:_vm._u([{key:"additional_cost",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.purchase_cost_currency_id,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('additional_cost', $event)}}})]}},{key:"buying_agent_fee",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"buying_agent_fee_percentage",fn:function(ref){
            var value = ref.value;
return [_c('TMessagePercent',{attrs:{"value":value,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('buying_agent_fee_percentage', $event)}}})]}},{key:"cod_cost",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id,"editable":!_vm.updating && _vm.detail.type_id != 'Shipment'},on:{"change":function($event){return _vm.updateOrder('cod_cost', $event)}}})]}},{key:"estimated_freight_charge",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '6' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Compensation","data":_vm.detail,"fields":_vm.compensationFields,"splitLeft":7},scopedSlots:_vm._u([{key:"compensation",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"compensated",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"compensation_unsolved",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '6' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Info","data":_vm.detail,"fields":_vm.otherFields,"splitLeft":7},scopedSlots:_vm._u([{key:"sales_organization_id",fn:function(ref){
            var value = ref.value;
return [_c('SMessageOrganization',{attrs:{"id":value}})]}},{key:"transport_organization_id",fn:function(ref){
            var value = ref.value;
return [_c('SMessageOrganization',{attrs:{"id":value}})]}},{key:"created_at",fn:function(ref){
            var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"small":""}})]}},{key:"updated_at",fn:function(ref){
            var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value,"small":""}})]}},{key:"payment_due_date",fn:function(ref){
            var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"editable":!_vm.updating,"content":value,"dateOnly":""},on:{"change":function($event){return _vm.updateOrder('payment_due_date', $event)}}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '6' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Other","data":_vm.detail,"fields":_vm.freightChargeFields,"splitLeft":7},scopedSlots:_vm._u([{key:"auto_add_contract",fn:function(ref){
            var value = ref.value;
return [_c('TSwitch',{attrs:{"checked":value,"disabled":_vm.updating},on:{"change":function($event){return _vm.updateOrder('auto_add_contract', $event)}}})]}},{key:"is_pay_freight_charge_in_lading_bill",fn:function(ref){
            var value = ref.value;
return [_c('TSwitch',{attrs:{"checked":value,"disabled":""}})]}},{key:"is_pay_freight_charge_in_lading_bill-header",fn:function(){return [_c('TMessage',{attrs:{"content":"Pay freight charge in lading bill","bold":""}})]},proxy:true},{key:"is_allow_custom_freight_rate",fn:function(ref){
            var value = ref.value;
return [_c('TSwitch',{attrs:{"checked":value,"disabled":_vm.updating},on:{"change":function($event){return _vm.updateOrder('is_allow_custom_freight_rate', $event)}}})]}},{key:"is_allow_custom_freight_rate-header",fn:function(){return [_c('TMessage',{attrs:{"content":"Allow the use of the custom freight rate","bold":""}})]},proxy:true},{key:"freight_rate",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('freight_rate', $event)}}})]}}])})],1),_c('CCol',{attrs:{"sm":"12","md":"6","lg":"6","xl":{ size: '4', order: '9' }}},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"Exchange","data":_vm.detail,"fields":_vm.exchangeFields,"splitLeft":7},scopedSlots:_vm._u([{key:"total_payment",fn:function(ref){
            var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":_vm.detail.service_fee_currency_id}})]}},{key:"payment_rate",fn:function(ref){
            var value = ref.value;
return [_c('TMessageNumber',{attrs:{"value":value,"editable":!_vm.updating},on:{"change":function($event){return _vm.updateOrder('payment_rate', $event)}}})]}}])})],1)],1),(!_vm.noItem)?_c('CRow',{staticClass:"mb-4"},[_c('CCol',[_c('TTableAdvance',{attrs:{"items":_vm.orderItems,"fields":_vm.itemFields,"store":("order." + _vm.orderItemStore),"title":"Items","resource":"","noFilter":"","removable":"","creatable":""},on:{"click-create":function($event){_vm.showCreateItem = true}},scopedSlots:_vm._u([{key:"purchase",fn:function(ref){
            var item = ref.item;
return [_c('td',[(item.distributions)?_c('div',[(
                    !item.distributions.length ||
                    item.distributions.every(function (x) { return !x.purchase_item; })
                  )?_c('TMessageNotFound'):_vm._e(),_vm._l((item.distributions),function(dist){return _c('div',{key:dist.id,staticClass:"mb-2"},[(dist.purchase_item)?_c('CRow',[_c('CCol',{attrs:{"col":"6"}},[_c('TLink',{attrs:{"content":dist.purchase_item.purchase_id,"to":_vm.lodash.getReferenceLink(
                            'purchase',
                            dist.purchase_item.purchase_id
                          ),"messageOptions":{ bold: true, size: 'h5' }}}),_c('TMessage',{attrs:{"content":"Price","addClasses":['d-flex flex-wrap']},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(": "),_c('TMessageMoney',{staticClass:"ml-1",attrs:{"amount":dist.price,"currency":item.currency_id}})]},proxy:true}],null,true)}),_c('TMessage',{attrs:{"content":"Quantity","addClasses":['d-flex flex-wrap']},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(": "),_c('TMessageNumber',{staticClass:"ml-1",attrs:{"messageOptions":{ bold: true },"value":dist.quantity,"suffix":_vm.$t('Piece')}})]},proxy:true}],null,true)})],1),(dist.purchase_item)?_c('CCol',{attrs:{"col":"6"}},[_c('div',[_c('TMessage',{attrs:{"content":"Payment due date","bold":""}}),_c('TMessageDateTime',{attrs:{"content":dist.purchase_item.payment_due_date,"dateOnly":""}})],1),_c('div',[_c('TMessage',{attrs:{"content":"Expected delivery","bold":""}}),_c('TMessageDateTime',{attrs:{"content":dist.purchase_item.expected_delivery,"dateOnly":""}})],1)]):_vm._e()],1):_vm._e()],1)})],2):_vm._e()])]}},{key:"product",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('SCardProductItems',{attrs:{"item":item.product,"resource":"/sale/goods/products","widthAuto":""}})],1)]}},{key:"quantity",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":item.quantity,"editable":!_vm.orderItemLoading},on:{"change":function($event){return _vm.updateOrderItem('quantity', $event)}}})],1)]}},{key:"price",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price,"editable":!_vm.orderItemLoading,"currency":item.currency_id,"decimalLimit":6},on:{"change":function($event){return _vm.updateOrderItem('price', $event)}}})],1)]}},{key:"price_with_tax",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.price_with_tax,"currency":item.currency_id}})],1)]}},{key:"amount",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.amount,"currency":item.currency_id}})],1)]}},{key:"balance",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.balance,"currency":item.currency_id}})],1)]}},{key:"tax",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageMoney',{attrs:{"amount":item.tax,"currency":item.currency_id}})],1)]}},{key:"expected_delivery",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.expected_delivery,"dateOnly":"","showEditModal":"","editable":!_vm.orderItemLoading},on:{"change":function($event){return _vm.updateOrderItem('expected_delivery', $event)}}})],1)]}},{key:"tax_percent",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessagePercent',{attrs:{"value":item.tax_percent,"editable":!_vm.orderItemLoading},on:{"change":function($event){return _vm.updateOrderItem('tax_percent', $event)}}})],1)]}},{key:"discount_tax_per_tax_percent",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessagePercent',{attrs:{"value":item.discount_tax_per_tax_percent,"editable":!_vm.orderItemLoading},on:{"change":function($event){return _vm.updateOrderItem('discount_tax_per_tax_percent', $event)}}})],1)]}},{key:"note",fn:function(ref){
                          var item = ref.item;
return [_c('td',[_c('TMessageText',{attrs:{"value":item.note,"editable":!_vm.orderItemLoading},on:{"change":function($event){return _vm.updateOrderItem('note', $event)}}})],1)]}}],null,false,2410108025)}),_c('SModalAddItem',{key:_vm.key,attrs:{"show":_vm.showCreateItem,"currency_id":_vm.detail.purchase_cost_currency_id},on:{"update:show":function($event){_vm.showCreateItem=$event},"click-add":_vm.addItem}})],1)],1):_vm._e(),(
        _vm.detail.type_id == 'Shipment' &&
        _vm.detail.trackings &&
        !_vm.detail.trackings.length
      )?_c('SModalAddTracking',{attrs:{"show":_vm.showAddTracking,"store":"order.order_shipment"},on:{"update:show":function($event){_vm.showAddTracking=$event}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }